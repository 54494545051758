// @ts-nocheck

import loadable from "@loadable/component";

const JSON = loadable(
  () =>
    new Promise((r, c) =>
      import("react-json-view").then((result) => r(result.default), c)
    )
);

// https://github.com/mac-s-g/react-json-view/issues/296
export const ReactJson = ({ src }: { src: any }) => <JSON src={src} collapsed enableClipboard={false} />;